import { z } from "zod";
import { IntegrationsWorkProfile, ObjectName } from "@prisma/client";
import { Cube9SyncConfig, EnumMapSyncConfig } from "prisma/zod-add-schema";

export const IntegrationName = z.enum([
  "hubspot",
  "quickbooks",
  "salesforce",
  "slack",
  "googleSheets",
  "monday",
  "greenInvoice",
  "stripe",
  "pipedrive",
  "netsuite",
  "chargebee",
  "priority",
  "cube9",
]);

export type IntegrationName = z.infer<typeof IntegrationName>;

export const HubspotCredentialsModel = z.object({
  integrationName: z.literal("hubspot"),
  // appToken: z.string(),
  accessToken: z.string(),
  expiresIn: z.number(),
  refreshToken: z.string(),
  tokenType: z.string(),
  updatedAt: z.number(),
});

export const QuickbooksCredentialsModel = z.object({
  integrationName: z.literal("quickbooks"),
  authToken: z.any(),
  realmId: z.string(),
  updatedAt: z.number(),
});

export const SalesforceCredentialsModel = z.object({
  integrationName: z.literal("salesforce"),
  // TODO, all not optional, remove once all client upgraded
  instanceUrl: z.string().optional(),
  accessToken: z.string().optional(),
  refreshToken: z.string().optional(),
  updatedAt: z.number(),
});

export const PipedriveCredentialsModel = z.object({
  integrationName: z.literal("pipedrive"),
  access_token: z.string(),
  refresh_token: z.string(),
  scope: z.string(),
  expires_in: z.number().int().positive(),
  api_domain: z.string().url(),
  updatedAt: z.number(),
});

export const NetsuiteCredentialsModel = z.object({
  integrationName: z.literal("netsuite"),
  accessToken: z.string(),
  updatedAt: z.number(),
  refreshToken: z.string().nullish(),
  tokenType: z.string().optional(),
  expiresIn: z.number().optional(),
  accountId: z.string(), // e.g. "1234567_SB2"
});

export const ChargebeeCredentialsModel = z.object({
  integrationName: z.literal("chargebee"),
  accessToken: z.string(),
  updatedAt: z.number(),
});

export const credentialsModel = z.discriminatedUnion("integrationName", [
  HubspotCredentialsModel,
  QuickbooksCredentialsModel,
  SalesforceCredentialsModel,
  z.object({
    integrationName: z.literal("greenInvoice"),
  }),
  z.object({
    integrationName: z.literal("stripe"),
  }),
  PipedriveCredentialsModel,
  NetsuiteCredentialsModel,
  ChargebeeCredentialsModel,
]);

export const GreenInvoiceEditableCredentialsModel = z.object({
  integrationName: z.literal("greenInvoice"),
  connected: z.boolean(),
  apiKey: z.string(),
  apiSecret: z.string(),
});

export const StripeEditableCredentialsModel = z.object({
  integrationName: z.literal("stripe"),
  connected: z.boolean(),
  apiKey: z.string(),
  apiSecret: z.string(),
});

export const ChargebeeEditableCredentialsModel = z.object({
  integrationName: z.literal("chargebee"),
  connected: z.boolean(),
  siteName: z.string(),
  apiKey: z.string(),
});

export const editableCredentialsModel = z.discriminatedUnion(
  "integrationName",
  [
    GreenInvoiceEditableCredentialsModel,
    StripeEditableCredentialsModel,
    ChargebeeEditableCredentialsModel,
  ],
);

export const syncExpression = z.object({
  expression: z.string(),
  jsonLogic: z.any(),
});

export type SyncExpression = z.infer<typeof syncExpression>;

export const SyncMode = z.enum(["automatic", "staged"]).default("staged");
export type SyncMode = z.infer<typeof SyncMode>;

export const ProductSyncModel = z
  .object({
    readSyncMode: SyncMode,
    createSyncMode: SyncMode,
    writeSyncMode: SyncMode,
    condition: z.enum(["always", "read-only", "never"]),
  })
  .default({ condition: "always" });

export const DealSyncModel = z
  .object({
    readSyncMode: SyncMode,
    createSyncMode: SyncMode,
    writeSyncMode: SyncMode,
    condition: z.enum(["always", "has-close-won", "read-only", "never"]),
    parseComplexDealDescription: z
      .enum(["none", "description", "file", "description-and-file"])
      .default("none"),
  })
  .default({ condition: "has-close-won" });

export const InvoiceSyncModel = z
  .object({
    readSyncMode: SyncMode,
    createSyncMode: SyncMode,
    writeSyncMode: SyncMode,
    condition: z.enum(["always", "read-only", "never"]),
    syncRouteExpression: syncExpression.nullish(),
  })
  .default({ condition: "always" });

export const CustomerSyncCondition = z.enum([
  "always",
  "never",
  "has-close-won",
  "has-invoice",
  "read-only",
]);

export const CustomerSyncModel = z
  .object({
    readSyncMode: SyncMode,
    createSyncMode: SyncMode,
    writeSyncMode: SyncMode,
    condition: CustomerSyncCondition,
  })
  .default({ condition: "always" });

export const syncDefaults = {
  products: {
    readSyncMode: "automatic",
    createSyncMode: "staged",
    writeSyncMode: "staged",
    condition: "always",
  },
  customers: {
    readSyncMode: "automatic",
    createSyncMode: "staged",
    writeSyncMode: "staged",
    condition: "always",
  },
  deals: {
    readSyncMode: "automatic",
    createSyncMode: "staged",
    writeSyncMode: "staged",
    condition: "always",
    parseComplexDealDescription: "none",
  },
  invoices: {
    readSyncMode: "automatic",
    createSyncMode: "staged",
    writeSyncMode: "staged",
    condition: "always",
  },
} as const;

export const syncModel = z.object({
  products: ProductSyncModel,
  customers: CustomerSyncModel,
  deals: DealSyncModel.optional(),
  invoices: InvoiceSyncModel.nullish(),
  fieldsMappings: Cube9SyncConfig.nullish(),
  integrationDisabled: z.boolean().optional(),
  integrationMasterReadOnly: z.boolean().optional(),
});

export const HubspotIntegrationConfigModel = z.object({
  integrationName: z.literal("hubspot"),
  sync: syncModel.nullish(),
});

export const QuickbooksIntegrationConfigModel = z.object({
  integrationName: z.literal("quickbooks"),
  productsAccountId: z.string().nullish(),
  servicesAccountId: z.string().nullish(),
  sync: syncModel.nullish(),
  // invoiceSync: syncExpression.nullish(),
});

export const SalesforceIntegrationConfigModel = z.object({
  integrationName: z.literal("salesforce"),
  pricebookId: z.string().nullish(),
  sync: syncModel.nullish(),
});

export const GreenInvoiceIntegrationConfigModel = z.object({
  integrationName: z.literal("greenInvoice"),
  // businessId: z.string().nullish(),
  // documentCurrency: z.string().nullish(),
  sync: syncModel.nullish(),
  // invoiceSync: syncExpression.nullish(),
  documentType: z.enum(["invoice", "proforma-invoice"]).default("invoice"),
});

export const StripeIntegrationConfigModel = z.object({
  integrationName: z.literal("stripe"),
  // businessId: z.string().nullish(),
  // documentCurrency: z.string().nullish(),
  // collection_method
  invoiceCollectionMethod: z
    .enum(["charge_automatically", "send_invoice"])
    .default("charge_automatically"),
  sync: syncModel.nullish(),
  // invoiceSync: syncExpression.nullish(),
});

export const PipedriveIntegrationConfigModel = z.object({
  integrationName: z.literal("pipedrive"),
  // stages: z
  //   .array(
  //     z.object({
  //       id: z.number(),
  //       name: z.string(),
  //       deal_probability: z.number().optional(),
  //     }),
  //   )
  //   .optional(),
  sync: syncModel.nullish(),
});

export const NetsuiteIntegrationConfigModel = z.object({
  integrationName: z.literal("netsuite"),
  sync: syncModel.nullish(),
});

export const ChargebeeIntegrationConfigModel = z.object({
  integrationName: z.literal("chargebee"),
  sync: syncModel.nullish(),
});

export const integrationConfigModel = z.discriminatedUnion("integrationName", [
  HubspotIntegrationConfigModel,
  QuickbooksIntegrationConfigModel,
  SalesforceIntegrationConfigModel,
  GreenInvoiceIntegrationConfigModel,
  StripeIntegrationConfigModel,
  PipedriveIntegrationConfigModel,
  NetsuiteIntegrationConfigModel,
  ChargebeeIntegrationConfigModel,
]);

export type IntegrationConfigModel = z.infer<typeof integrationConfigModel>;
export type IntegrationConfigModelSync = IntegrationConfigModel["sync"];

const fieldMappingInfoSchema = z.object({
  rename: z.string().optional(),
  exists: z.boolean(),
  enumMap: EnumMapSyncConfig.optional(),
  integrationDefaultValue: z.string().nullish(),
});

const fieldMappings = z
  .object({
    [ObjectName.product]: z.record(fieldMappingInfoSchema),
    [ObjectName.customer]: z.record(fieldMappingInfoSchema),
    [ObjectName.contactPerson]: z.record(fieldMappingInfoSchema),
    [ObjectName.deal]: z.record(fieldMappingInfoSchema),
    [ObjectName.dealLine]: z.record(fieldMappingInfoSchema),
  })
  .optional();

export const emptyFieldMappings = {
  [ObjectName.product]: {},
  [ObjectName.customer]: {},
  [ObjectName.contactPerson]: {},
  [ObjectName.deal]: {},
  [ObjectName.dealLine]: {},
};

export const HubspotIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("hubspot"),
  fieldMappings,
});

export const QuickbooksIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("quickbooks"),
  multiCurrencyEnabled: z.boolean().default(false),
  defaultCurrency: z.string().default("USD"),
});

export type IntegrationFieldMappingInfo = z.infer<
  typeof fieldMappingInfoSchema
>;

export const SalesforceIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("salesforce"),
  fieldMappings,
});

export const GreenInvoiceIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("greenInvoice"),
  defaultCurrency: z.string().default("USD"),
});

export const StripeIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("stripe"),
  defaultCurrency: z.string().default("USD"),
});

export const PipedriveIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("pipedrive"),
  defaultCurrency: z.string().default("USD"),
});

export const NetsuiteIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("netsuite"),
  defaultCurrency: z.string().default("USD"),
});

export const ChargebeeIntegrationSystemConfigModel = z.object({
  integrationName: z.literal("chargebee"),
  defaultCurrency: z.string().default("USD"),
});

export const integrationSystemConfigModel = z.discriminatedUnion(
  "integrationName",
  [
    HubspotIntegrationSystemConfigModel,
    QuickbooksIntegrationSystemConfigModel,
    SalesforceIntegrationSystemConfigModel,
    GreenInvoiceIntegrationSystemConfigModel,
    StripeIntegrationSystemConfigModel,
    PipedriveIntegrationSystemConfigModel,
    NetsuiteIntegrationSystemConfigModel,
    ChargebeeIntegrationSystemConfigModel,
  ],
);

export const integrationModel = z.object({
  organizationId: z.string(),
  integrationName: IntegrationName,
  integrationsWorkProfile: z.nativeEnum(IntegrationsWorkProfile),
  integrationsWorkProfileInfo: z.string().nullish(),
  integrationId: z.string(),
  credentials: credentialsModel.nullable(),
  hasCredentials: z.boolean(),
  editableCredentials: editableCredentialsModel.nullable(),
  integrationConfig: integrationConfigModel.nullable(),
  integrationSystemConfig: integrationSystemConfigModel.nullable(),
  enabled: z.boolean().optional(),
  version: z.string().optional(),
  sandbox: z.boolean().optional(),
});

export type CredentialsModel = z.infer<typeof credentialsModel>;
export type IntegrationModel = z.infer<typeof integrationModel>;
